<template>
  <div class="">
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form payment-form" id="customer_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="card card-custom gutter-b example example-compact border border-secondary mb-3">
            <div class="card-header bg-secondary">
              <h3 class="card-title  text-white">{{ checkPayment ? "Đặt lịch đóng tiền" : "Nhập kết quả tư vấn" }}</h3>
            </div>
            <!--begin::Form-->
            <div class="card-body">
              <div v-if="checkPayment || checkResult">
              <hr>
              <div class="row">
                <div class="col-md-12 list-contract">
                  <h4 class="title-contract">DANH SÁCH HỢP ĐỒNG</h4>
                  <a v-if="contracts.length === 0" class="contract" @click="handleCreateContract()">Tạo hợp đồng </a>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12" v-if="contracts.length > 0">
                  <el-table
                          :data="contracts"
                          stripe
                          border
                          style="width: 100%"
                          tableSize=" table-bordered table-striped table-hover "
                          class="table-hover table-responsive-lg table-customer table-list-contract"
                  >
                    <el-table-column prop="user_name" label="Hợp đồng" width="1200">
                      <template v-slot="scope">
                        Hợp đồng {{ scope.row.user_name }} - {{ scope.row.created_at }}
                      </template>
                    </el-table-column>
                    <el-table-column
                            prop="id"
                            label="Hành động">
                      <template v-slot="scope">
                        <div v-if="scope.row.total_paid > 0">
                          <el-row>
                            <el-tooltip content="Xem hợp đồng" placement="top">
                              <a @click="handleViewContract(scope.row)"
                                 class="btn btn-success btn-sm btn-icon waves-effect waves-themed">
                                <i class="fal fa-eye"></i>
                              </a>
                            </el-tooltip>
                          </el-row>
                        </div>
                        <div v-else>
                          <el-row class="action-table">
                            <el-tooltip content="Sửa hợp đồng" placement="top">
                              <a @click="handleUpdateContract(scope.row)"
                                 class="btn btn-info btn-sm btn-icon waves-effect waves-themed">
                                <i class="fal fa-edit"></i>
                              </a>
                            </el-tooltip>
                            <el-tooltip content="Xóa hợp đồng" placement="top">
                              <a @click="handleDeleteContract(scope.row)"
                                 class="btn btn-danger btn-sm btn-icon waves-effect waves-themed">
                                <i class="fal fa-trash"></i>
                              </a>
                            </el-tooltip>
                          </el-row>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div v-else class="no-contract">
                  <p>Chưa có hợp đồng!</p>
                </div>
              </div>
              <hr>
              </div>
              <div v-if="checkPayment">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="title-contract">ĐẶT LỊCH</h4>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 text-right">
                    <label class="form-label">Thời gian <span class="text-danger">(*)</span></label>
                  </div>
                  <div class="col-md-7">
                    <ValidationProvider vid="ngay_hen" name="Thời gian" rules="required" v-slot="{ errors,classes }">
                      <edutalk-date-picker
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              :arrow-control="true"
                              v-model="payment_schedule.ngay_hen"
                              placeholder="Thời gian"
                              :picker-options="pickerOptions"
                              type="datetime"
                              :class="classes"
                              :clearable="false"
                      >
                      </edutalk-date-picker>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 text-right">
                    <label class="form-label">Trung tâm <span class="text-danger">(*)</span></label>
                  </div>
                  <div class="col-md-7">
                    <ValidationProvider vid="center_id" name="Trung tâm" rules="required" v-slot="{ errors,classes }">
                      <el-select v-model="payment_schedule.center_id" filterable class="w-100" placeholder="Chọn trung tâm" disabled>
                        <el-option
                                v-for="item in centers"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                :class="classes"
                        >
                          <span style="float: left">{{ item.name }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 text-right">
                    <label class="form-label">Chi nhánh <span class="text-danger">(*)</span></label>
                  </div>
                  <div class="col-md-7">
                    <ValidationProvider vid="branch_id" name="Chi nhánh" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select v-model="payment_schedule.branch_id" filterable class="w-100" placeholder="Chọn chi nhánh" disabled>
                        <el-option
                                v-for="item in branches"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                :class="classes"

                        >
                          <span style="float: left">{{ item.name }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row save-payment-schedule">
                  <button class="btn btn-primary float-right mt-3 ml-3" type="submit">
                    <i v-if="check_loading" class="el-icon-loading"></i>
                    Lưu
                  </button>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="title-contract">THÔNG TIN TƯ VẤN</h4>
                  </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <div class="row form-group">
                    <div class="col-md-2 d-flex align-items-center">
                      <label class=" form-label">Kết quả tư vấn</label>
                    </div>
                    <div class="col-md-5">
                      <el-select v-model="customer.ket_qua_tu_van" clearable class="w-100" placeholder="Nhập kết quả tư vấn" disabled>
                        <el-option
                            v-for="item in ket_qua_tu_van"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id">
                          <span style="float: left">{{ item.value }}</span>
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2 d-flex align-items-center">
                      <label for="nguyen_nhan_khong_dang_ky" class="form-label">Ghi chú</label>
                    </div>
                    <div class="col-md-10">
                      <el-input id="nguyen_nhan_khong_dang_ky" v-model="customer.nguyen_nhan_khong_dang_ky" type="text" class="w-100" placeholder="Nguyên nhân không đăng kí"></el-input>
                    </div>
                  </div>
                  <div class="row form-group" v-if="customer.ket_qua_tu_van">
                    <div class="col-md-2 d-flex align-items-center">
                      <label for="tuitionPayment" class="form-label">Hình thức thanh toán học phí <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-md-5">
                      <ValidationProvider vid="tuitionPayment" name="Hình thức thanh toán học phí" rules="required" v-slot="{ errors,classes }">
                        <el-select id="tuitionPayment" v-model="customer.tuitionPayment"
                                   clearable filterable
                                   :class="classes"
                                   ref="tuitionPayment"
                                   class="w-100">
                          <el-option
                              v-for="item in tuitionPaymentMethods"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="form-group row save-payment-schedule">
                  <el-button class="float-right mt-3 ml-3" @click="cancelNhapKQTV()" type="info">
                    Hủy bỏ
                  </el-button>
                  <el-button class="float-right mt-3 ml-3" @click="nhapKQTV()" type="primary" :loading="check_loading">
                    Lưu
                  </el-button>
                </div>
              </div>
            </div>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
    <!--eslint-disable-->
  <el-dialog :title="dialog.title" class="responsive contract" :visible.sync="dialogFormVisible">
    <el-row :gutter="10" class="pr-2 pl-2">
      <contract-form
          @completed="completedCreate"
          v-if="dialogFormVisible"
          :customer-id="customer_id"
          :center-id="payment_schedule.center_id"
          :branch-id="payment_schedule.branch_id"
          :contract="contract"
          :check-edit="checkEdit"
          :check-view="checkView"
          @close="closeDialog">
      </contract-form>
    </el-row>
  </el-dialog>
</div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {
      BRANCH_LIST,
      CENTER_LIST,
      CUSTOMER_FIND,
      GET_INFO_PAYMENT_SCHEDULE, LIST_TUITION_PAYMENT_METHOD, NHAP_KET_QUA_TU_VAN, PAYMENT_SCHEDULE
    } from "@/core/services/store/customer/customer.module";
    import {mapGetters, mapState} from "vuex";
    import ContractForm from "@/views/pages/customer/contract-form";
    import {DELETE_CONTRACT} from "@/core/services/store/contract/contract.module";
    import Swal from 'sweetalert2';
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    export default {
        components: {
            ContractForm
        },
        name: "DatLichDongTien",
        data() {
            return {
                ket_qua_tu_van: [
                    {id: 0, value: "Khách hàng không đăng ký"},
                    {id: 1, value: "Khách hàng đăng ký"},
                ],
                tuitionPaymentMethods: [],
                nguoi_ra_quyet_dinh: [
                    {id: 'Phụ huynh', value: "Phụ huynh"},
                    {id: 'Bạn bè', value: "Bạn bè"},
                    {id: 'Bản thân', value: "Bản thân"},
                ],
                customer: {
                    ket_qua_tu_van: 0,
                    khach_hang_van_dang_con_van_de: [],
                    nguyen_nhan_khong_dang_ky: '',
                    tuitionPayment: '' //danh sách hình thức thanh toán học phí
                },
                is_show_room: false,
                check_loading: false,
                check_loading_destroy: false,
                check_edit: false,
                centers: [],
                branches: [],
                // Cache
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false,
                },
                pickerOptions: {
                    disabledDate: this.disabledDate,
                },
                payment_schedule: {
                    'center_id': '',
                    'branch_id': '',
                    'ngay_hen': ''
                },
                contracts: [],
                info: {

                },
                dialogFormVisible: false,
                dialog: {
                    title: ''
                },
                checkEdit: false,
                checkView: false,
                contract: null,
                customer_id: null,
                checkPayment: false,
                ngayHenOld: '',
                checkResult: false
            }
        },
      created() {
          this.getTuitionPaymentMethods();
      },
      watch: {
        customer: {
          immediate: true,
          deep: true,
          handler() {
            for (const key in this.customer) {
              if(key === 'nguyen_nhan_khong_dang_ky' || key === 'tuitionPayment'){
                if (this.customer[key] || this.contracts.length > 0){
                  addEventListener("beforeunload", (event) => {
                    event.preventDefault();
                    return event.returnValue = "Are you sure you want to exit?";
                  }, {capture: true});
                }
              }
            }
          }
        }
      },
      async mounted() {
          this.getInfoCustomer();
          if (Number(this.$route.query.payment) === 1) {
              this.checkPayment = true;
              await this.$store.dispatch(SET_BREADCRUMB, [
                  {title: "Khách hàng", route: "customer-list"},
                  {title: "Đặt lịch đóng tiền", icon: 'fal el-icon-money'}
              ]);
          } else {
              this.checkPayment = false;
              await this.$store.dispatch(SET_BREADCRUMB, [
                  {title: "Khách hàng", route: "customer-list"},
                  {title: "Nhập kết quả tư vấn", icon: 'fal el-icon-money'}
              ]);
          }
         this.getCenters();
         this.getBranches();
         this.getInfoBeforePaymentSchedule();
         this.$forceUpdate();
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            ...mapGetters(['currentUser'])
        },
        methods: {
            closeDialog() {
                this.dialogFormVisible = false;
            },
            getInfo() {
                if (this.$route.query.promotion_id) {
                    this.info.promotion_id = this.$route.query.promotion_id;
                }
                if (this.$route.query.classroom_id) {
                    this.info.classroom_id = this.$route.query.classroom_id;
                }
                if (this.$route.query.customer_id) {
                    this.info.customer_id = this.$route.query.customer_id;
                }
                if (this.$route.query.search_course) {
                    this.info.search_course = this.$route.query.search_course;
                }
                if (this.$route.query.branch_id) {
                    this.info.branch_id = this.$route.query.branch_id;
                }
                if (this.$route.query.branch_id) {
                    this.payment_schedule.branch_id = this.$route.query.branch_id ?? 0;
                }
            },
            disabledDate (date) {
                let tempDate = new Date();
                let currentDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0,0);
                let extentDate = '';
                if (this.ngayHenOld) {
                    extentDate = this.$moment(this.ngayHenOld);
                } else {
                    extentDate = this.$moment().add(2, 'days');
                }
                return date < currentDate || date > extentDate;
            },
            getCenters() {
                this.$store.dispatch(CENTER_LIST)
                    .then((response) => {
                        if (response.status === 422) {
                            this.$notify({
                                title: 'Warning',
                                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                                type: 'warning'
                            });
                        } else {
                            this.centers = response.data;
                        }
                    });
            },
            getTuitionPaymentMethods() { //danh sách hình thức thanh toán học phí
                this.$store.dispatch(LIST_TUITION_PAYMENT_METHOD).then((res) => {
                  this.tuitionPaymentMethods = res.data
                });
            },
            async getBranches() {
                await this.$store.dispatch(BRANCH_LIST, {center_id: this.payment_schedule.center_id })
                    .then((response) => {
                        if (response.status === 422) {
                            this.$notify({
                                title: 'Warning',
                                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                                type: 'warning'
                            });
                        } else {
                            this.branches = response.data;
                            // this.payment_schedule.branch_id = response.data[0].id ?? '';
                        }
                    });
            },
            onSubmit() {
                this.check_loading = true;
                if ((this.checkPayment && this.contracts.length === 0) || (!this.checkPayment && this.checkResult && this.contracts.length === 0)) {
                    Swal.fire('Thông báo', 'Bạn chưa tạo hợp đồng', 'error');
                    this.check_loading = false;
                    return false;
                }
                this.$store.dispatch(PAYMENT_SCHEDULE, {
                    id: this.customer.id,
                    ngay_hen: this.payment_schedule.ngay_hen,
                    center_id: this.payment_schedule.center_id,
                    branch_id: this.payment_schedule.branch_id
                }).then((res) => {
                    this.check_loading = false;
                    if (!res.error) {
                        this.$router.push({name: "customer-list"}).then(() => {
                            this.$notify({
                                title: 'Đặt lịch đóng tiền',
                                message: 'Thành công',
                                type: 'success'
                            });
                        })
                    } else {
                        this.$notify({
                            title: 'Đặt lịch đóng tiền',
                            message: res.message,
                            type: 'error'
                        });
                    }}).catch(() => {
                    this.$notify({
                        title: 'Đặt lịch đóng tiền',
                        message: 'Có lỗi xảy ra',
                        type: 'error'
                    });
                    this.check_loading = false;
                });
            },
            fetchCustomer() {
                this.$store.dispatch(CUSTOMER_FIND, this.$route.params.id).then((res) => {
                    this.customer = res.data;
                });
            },
            async getInfoBeforePaymentSchedule() {
                await this.getInfo();
                await this.$store.dispatch(GET_INFO_PAYMENT_SCHEDULE, this.info).then((res) => {
                    if (!res.error) {
                        this.payment_schedule.center_id = res.data.center_check;
                        this.payment_schedule.branch_id = res.data.branch_check;
                        if (res.data.max_ngay_hen) {
                            this.payment_schedule.ngay_hen = res.data.max_ngay_hen.ngay_hen_dong_tien ?? '';
                            this.ngayHenOld = res.data.max_ngay_hen.ngay_hen_dong_tien ?? '';
                        }
                        this.contracts = res.data.list_contract;
                        if (this.contracts.length > 0){
                          this.customer.tuitionPayment = this.contracts[0].tuition_payment_id;
                        }
                        this.$forceUpdate();
                    } else {
                        this.$notify({
                            title: 'Lấy thông tin đặt lịch đóng tiền',
                            message: res.message,
                            type: 'error'
                        });
                    }});
            },
            handleCreateContract() {
                this.dialog.title = 'Tạo hợp đồng';
                this.dialogFormVisible = true;
                this.customer_id = this.$route.params.customer_id;
                this.center_id = this.payment_schedule.center_id;
                this.checkEdit = false;
                this.checkView = false;
            },
            handleUpdateContract(contract) {
                this.dialog.title = 'Sửa hợp đồng';
                this.dialogFormVisible = true;
                this.checkEdit = true;
                this.checkView = false;
                this.contract = contract;
            },
            handleViewContract(contract) {
                this.dialog.title = 'Chi tiết hợp đồng';
                this.dialogFormVisible = true;
                this.checkEdit = false;
                this.checkView = true;
                this.contract = contract;
            },
            handleDeleteContract(item) {
                Swal.fire({
                    title: 'Bạn có chắc chắn muốn xóa hợp đồng',
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Đồng ý',
                    showLoaderOnConfirm: true,
                    cancelButtonText: 'Hủy',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_CONTRACT, { id: item.id }).then((res) => {
                          let index = _.findIndex(this.contracts, function (contract) {
                              return contract.id === item.id;
                          });
                          this.contracts.splice(index, 1);
                          Swal.fire('Xóa hợp đồng thành công', '', 'success');
                          this.contract = null;
                        }).catch((err) => {
                          this.noticeMessage('error','Thất bại', err.data.message);
                        });
                    }
                });
            },
            completedCreate(item) {
                if (this.checkEdit || this.checkView) {
                    this.dialogFormVisible = false;
                } else {
                    this.contracts.push(item);
                    this.dialogFormVisible = false;
                }
              addEventListener("beforeunload", (event) => {
                event.preventDefault();
                return event.returnValue = "Are you sure you want to exit?";
              }, {capture: true});
            },
            getInfoCustomer() {
                this.$store.dispatch(CUSTOMER_FIND, this.$route.query.customer_id).then((response) => {
                    this.customer = response.data;
                    if (Number(this.$route.query.result) === 1) {
                        this.customer.ket_qua_tu_van = 1;
                        this.checkResult = true;
                    } else {
                        this.customer.ket_qua_tu_van = 0;
                        this.checkResult = false;
                    }
                });
            },
            nhapKQTV() {
              if (this.checkResult && this.contracts.length === 0) {
                  Swal.fire('Thông báo', 'Bạn chưa tạo hợp đồng', 'error');
                  return;
              }
              if (this.customer.ket_qua_tu_van && !this.customer.tuitionPayment){
                this.$refs.form.setErrors({tuitionPayment: 'Hình thức thanh toán học phí không được để trống'});
                return;
              }
              this.check_loading = true;
              this.customer.center_check = this.payment_schedule.center_id;
              if (this.customer.khach_hang_van_dang_con_van_de[0] == "") {
                  let problems = this.customer.khach_hang_van_dang_con_van_de;
                  problems.shift();
                  this.customer.khach_hang_van_dang_con_van_de = problems;
              }
              let contract_id = '';
              if (this.contracts.length > 0) contract_id = this.contracts[0].id;
              let params = {...this.customer, contract_id: contract_id}
              this.$store.dispatch(NHAP_KET_QUA_TU_VAN, params).then(() => {
                  let resultRouter = '';
                  if (this.customer.ket_qua_tu_van){
                    resultRouter = this.$router.push({name: "sale-contracts"})
                  }else{
                    resultRouter = this.$router.push({name: "customer-list"})
                  }
                  resultRouter.then(() => {
                    this.$notify({
                      title: 'Nhập kết quả tư vấn',
                      message: 'Thành công',
                      type: 'success'
                    });
                  })
                }).catch((err) => {
                      this.$notify({
                          title: 'Nhập kết quả tư vấn',
                          message: err.data.message,
                          type: 'error'
                      });
              }).finally(() => {
                this.check_loading = false;
              });
            },
            cancelNhapKQTV () {
              history.go(-1);
            }
        }
    }
</script>

<style scoped>

</style>
